import React from 'react'
import { Button } from 'reactstrap'

const statuses = {
    'running': {
        icon: 'fas fa-circle text-success',
        text: 'Σε λειτουργία',
        textClass: '',
        action: 'poweroff',
    },
    'starting': {
        icon: 'fas fa-circle text-warning',
        text: 'Εκκίνηση',
        textClass: 'text-warning',
        action: '',
    },
    'off': {
        icon: 'fas fa-circle text-muted',
        text: 'Απενεργοποιημένος',
        textClass: 'text-muted',
        action: 'poweron',
    },
    'initializing': {
        icon: 'fas fa-circle text-muted',
        text: 'Αρχικοποίηση',
        textClass: 'text-muted',
        action: '',
    },
}

const ServerStatus = (props) => {

    return (
        <Button
            color='link'
            onClick={() => props.handleStatusClick(props.serverId, statuses[props.status].action)}>
                <i className={statuses[props.status].icon}></i>
                {' '}
                {statuses[props.status].text}
        </Button>
    )
}
 
export default ServerStatus