import React, { useState, useEffect } from 'react'
import ContactLead from './ContactLead'
import ContactEdit from './ContactEdit'
import { MainLoader } from '../Components/Loader/Loader'
import { getContactById, getContactLeads } from '../Api/Contacts'
import auth0Client from '../Auth'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { UncontrolledAlert, Row, Col, Card, CardBody, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export default function Contact(props) {
  const [contact, setContact] = useState(null)
  const [leads, setLeads] = useState(null)
  const [contactEditModal, setContactEditModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [plinkCopied, setPlinkCopied] = useState(false)

  const toggleContactEditModal = () => {
    setContactEditModal(!contactEditModal)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const handleCopy = () => {
    setPlinkCopied(true)

    setTimeout(function () {
      setPlinkCopied(false)
    }, 1000)
  }

  const reloadContact = (id) => {
    setContact(null)
    setLeads(null)
    getContactFromAPI(id)
  }

  const getContactFromAPI = async (id) => {
    const myIdToken = auth0Client.getIdToken()

    // Get Contact's info
    const contactData = await getContactById(id, myIdToken)
    setContact(contactData[0])

    // Get Contact's lead info
    const leadsData = await getContactLeads(id, myIdToken)
    setLeads(leadsData)
  }

  useEffect(() => {
    const { match: { params: { contactId } } } = props
    getContactFromAPI(contactId)
  }, [props])

  if (contact === null) {
    return (
      <MainLoader />
    )

  } else {
    return (
      <>
        <ContactEdit isOpen={contactEditModal}
          handleClose={toggleContactEditModal} reloadContact={reloadContact} {...contact} />

        <Row>
          <Col sm="12">
            <Card className="mb-3 app-card">
              <CardBody className="d-flex justify-content-between">
                <div>
                  <h3>
                    {contact.name}
                  </h3>
                  <div className="lead">
                    {contact.isClient ? <span className="badge badge-pill badge-success mr-2">Πελάτης</span> : ''}
                    {contact.isVendor ? <span className="badge badge-pill badge-info">Προμηθευτής</span> : ''}
                  </div>
                  <div className="lead">
                    {contact.email ?
                      <span className="badge mr-2">
                        <a href={"mailto:" + contact.email}>
                          <i className="fas fa-envelope pr-1"></i>
                          {contact.email}
                        </a>
                      </span> : ''
                    }
                    {contact.phone
                      ?
                      <span className="badge mr-2">
                        <a href={"tel:" + contact.phone}>
                          <i className="fas fa-phone pr-1"></i>
                          {contact.phone}
                        </a>
                      </span>
                      : ''
                    }

                  </div>
                </div>

                <div>
                  <ButtonDropdown direction="left" color="link" isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle color="link">
                      <i className="fas fa-ellipsis-v"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={toggleContactEditModal}>Επεξεργασία</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem disabled className='text-danger'>Διαγραφή</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </CardBody>
              <div className="clearfix"></div>
            </Card>
          </Col>

          {contact.debt &&
            <div className="col-sm-12">
              <UncontrolledAlert color='warning'>
                <i className="fas fa-money-bill-wave"></i>
                <span className="pl-3">Εκκρεμεί oφειλή <strong>€{contact.debt}</strong></span>
              </UncontrolledAlert >
            </div>
          }
        </Row>

        <Row>
          <Col sm={12}>
            <Card className="mb-3 app-card">
              <CardBody className="">
                <div className="form-group m-0">
                  <div className="mb-1">
                    <CopyToClipboard text={"http://admin.w3creations.gr/client-portal/" + contact.plink}
                      onCopy={handleCopy}>
                      <Button className="pl-0" color="link" >
                        <i className="fas fa-copy"></i>{' '}
                        {plinkCopied
                          ? "Copied!"
                          : "Copy link"
                        }
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <input type="text" className="form-control text-truncate" id="plink" name="plink" aria-describedby="plinkHelp"
                    value={"http://admin.w3creations.gr/client-portal/" + contact.plink} onClick={(e) => e.target.select()} />
                  <small id="plinkHelp">"Client portal" link</small>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          {leads ?
            (
              leads.length ?
                (
                  leads.map(lead => (
                    <ContactLead key={lead.id} {...lead} />
                  ))
                ) : (
                  <div className="container">
                    <div className="row">
                      <h3 style={{ margin: "0 auto" }}>No leads data</h3>
                    </div>
                  </div>
                )
            ) : <MainLoader />
          }
        </Row>
      </>
    )
  }
}