/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import auth0Client from './Auth'
import { UserLoader } from './Components/Loader/Loader'
import urlParse from 'url-parse'

class Callback extends Component {
  async componentDidMount() {

    let newLocation = '/'
    const referrerUrl = urlParse(document.referrer)    

    if (referrerUrl.host === window.location.host && referrerUrl.pathname !== '/callback' ) {
      newLocation = referrerUrl.pathname + referrerUrl.query
    }    

    await auth0Client.handleAuthentication()
    this.props.history.replace(newLocation)
  }

  render() {

    const css = {
      bgDiv : {
        display: "block",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      container : {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      },
      inner : {
        maxWidth: "100%",
        maxHeight: "100%",
        display: "block",
        margin: "0 auto",
      },
    }

    return (
      <div style={ css.bgDiv } className="bg-primary">
        <div style={ css.container }>
          <div style={ css.inner }>
            <UserLoader />
            <div>
              <span className="text-white">Checking user status</span>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(Callback);
