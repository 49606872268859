import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import SecuredRoute from './SecuredRoute/SecuredRoute'
import auth0Client from './Auth'
import Template from './Template'
import Callback from './Callback'
import ClientPortal from './ClientPortal/ClientPortal'

export default function App(props) {

  useEffect(() => {
    if (window.location.pathname === '/callback') return;
    auth0Client.silentAuth()
      .catch(err => {
        if (err.error !== 'login_required') console.log(err.error);
      })
  }, [])

  return (
    <Switch>
      <Route exact path='/callback' component={Callback} />
      <Route exact path='/client-portal/:key' component={ClientPortal} />
      <SecuredRoute path='/' component={Template} />
    </Switch>
  )
}
