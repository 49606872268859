import React, {Component, Fragment} from 'react'
import { Link } from 'react-router-dom'
import { MainLoader } from '../Components/Loader/Loader'
import { getLeadById } from '../Api/Leads'
import { Card } from 'reactstrap'
import auth0Client from '../Auth'
import Currency from '../Components/Text/Currency'

class Lead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: null,
    };
  }

  async componentDidMount() {

    const myIdToken = auth0Client.getIdToken()

    const { match: { params } } = this.props;

    // Get Lead's info
    const lead = await getLeadById(params.leadId, myIdToken);
    this.setState({
      lead: lead[0],
    });
  }

  render() {

    const lead = this.state.lead;

    if (lead === null) return (
      <MainLoader/>
    );

    const cost = lead.cost ? lead.cost : 0;
    const costPerc = (cost ? (lead.amount ? cost / lead.amount : 1 ) * 100 : 0);
    const profit = lead.amount ? lead.amount - lead.cost : 0;
    const profitPerc = profit ? profit / lead.amount * 100 : 0;


    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-12">
            <Card className="mb-3 app-card">
              <div className="card-body">

                <h3 className="m-0">
                  {lead.name}
                </h3>

              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Card className="mb-3 app-card">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <small className="text-muted">Πελάτης</small>
                  <div>
                    <Link className="card-link" to={`/contacts/${lead.pId}`}>
                      <span className="font-weight-bolder">{lead.pName}</span>
                    </Link>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <div>
                      <h5 className="m-0">
                        <Currency number={lead.amount} />
                      </h5>
                    </div>
                    <small className="text-success">{Math.round(profitPerc)}% profit</small>
                  </div>
                </li>
              </ul>
              <div className="progress rounded-0">
                <div className="progress-bar bg-success" role="progressbar"
                  style={{width: profitPerc+"%"}}
                  aria-valuenow={profitPerc}
                  aria-valuemin="0"
                  aria-valuemax="100">{"€"+profit}</div>
                <div className="progress-bar bg-danger progress-bar-striped" role="progressbar"
                  style={{width: costPerc+"%"}}
                  aria-valuenow={costPerc}
                  aria-valuemin="0"
                  aria-valuemax="100">{"€"+cost}</div>
              </div>
            </Card>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Lead;
