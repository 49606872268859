import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import auth0Client from '../../Auth'
import Gravatar from 'react-gravatar'
import {
  Container, Collapse, Navbar, NavbarToggler, Nav, NavItem,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'

export default function NavBar(props) {
  const [isOpen, setIsOpen] = useState(false)

  const signOut = () => {
    auth0Client.signOut()
    props.history.replace('/')
    close()
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <Navbar expand="md" fixed="top" className="navbar-dark bg-dark">
      <Container>
        <Link
          onClick={close}
          to="/"
          className="navbar-brand">
          <img src='logo192.png' alt='website logo' style={{height: 28}}/>
        </Link >
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>

            <NavItem>
              <NavLink
                onClick={close}
                to="/contacts"
                className="nav-link"
                activeClassName="active">
                <i className="fas fa-user">&nbsp;</i>
                   Επαφές
                </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={close}
                to="/leads"
                className="nav-link"
                activeClassName="active">
                <i className="fas fa-shopping-cart">&nbsp;</i>
                  Έργα
                </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={close}
                to="/servers"
                className="nav-link"
                activeClassName="active">
                <i className="fas fa-server">&nbsp;</i>
                  Servers
                </NavLink>
            </NavItem>

            {auth0Client.isAuthenticated() && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <Gravatar
                    email={auth0Client.getProfile().name}
                    size={24}
                    default="mp"
                    className="mr-2"
                    style={{
                      borderRadius: "50%"
                    }} />
                  {auth0Client.getProfile().name}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => { signOut() }}>
                    Αποσύνδεση
                      </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

