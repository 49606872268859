import React from 'react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'

const LeadsCard = (props) => {

  const cost = props.cost ? props.cost : 0;
  const costPerc = (cost ? (props.amount ? cost / props.amount : 1 ) * 100 : 0);
  const profit = props.amount ? props.amount - props.cost : 0;
  const profitPerc = profit ? profit / props.amount * 100 : 0;

  return (
    <div key={props.id} className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <div className="card mb-3 app-card app-card-anim">

        <div className="card-body">
          <Link className="card-link" to={`/leads/${props.id}`}>
            <h5 className="font-weight-bolder">{props.name}</h5>
          </Link>
          { props.desc &&
            <div className="text-muted font-weight-lighter">
              {props.desc}
            </div>
          }
        </div>

        <ul className="list-group list-group-flush">

          { props.pName && (
          <li className="list-group-item">
            <small className="text-muted">Πελάτης</small>
            <div>
              <Link className="card-link" to={`/contacts/${props.pId}`}>
                <span className="font-weight-bolder">{props.pName}</span>
              </Link>
            </div>
          </li>
          )}

          <li className="list-group-item">
            <div>
              <div>
                <h5 className="m-0">€{props.amount}</h5>
              </div>
              <small className="text-success">{Math.round(profitPerc)}% profit</small>
            </div>
          </li>
        </ul>

        <div className="progress rounded-0">
          <div className="progress-bar bg-danger" role="progressbar"
            style={{width: costPerc+"%"}}
            aria-valuenow={costPerc}
            aria-valuemin="0"
            aria-valuemax="100">{"€"+cost}</div>
          <div className="progress-bar bg-success" role="progressbar"
            style={{width: profitPerc+"%"}}
            aria-valuenow={profitPerc}
            aria-valuemin="0"
            aria-valuemax="100">{"€"+profit}</div>
        </div>

        <ul className="list-group list-group-flush">
          { props.ordered &&
            <li className="list-group-item">
              <div>
                <Moment titleFormat="D MMM YYYY" withTitle fromNow>{props.ordered}</Moment>
              </div>
              <small className="text-muted d-block d-sm-none">
                <Moment format="D MMM YYYY">{props.ordered}</Moment>
              </small>
            </li>
          }
        </ul>
      </div>
    </div>
  )
}

export default LeadsCard
