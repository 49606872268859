import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { invokeServerAction, getServerAction, } from "../Api/Servers"
import ServerStatus from "../Components/ServerStatus"
import { LoadingView } from "../Components/Loader/Loader"
import { Row, Col, Card, CardBody, } from 'reactstrap'


export default function ServersItem({ id: propsId, name, status, public_net, syncServers }) {
  const [actionRunning, setActionRunning] = useState(false)
  const [actionProgress, setActionProgress] = useState(0)

  const invokeAction = async (id, action) => {
    const actionResponse = await invokeServerAction(id, action)

    if (actionResponse.error) {
      console.log('ERROR')
    } else {
      setActionRunning(true)
      setActionProgress(0)
      let serverActionStatus = ""

      while (serverActionStatus !== 'success') {

        const serverAction = await getServerAction(propsId, actionResponse.action.id)


        if (serverAction.action.error) {
          alert('ERROR')
        } else {
          setActionProgress(serverAction.action.progress)

          if (serverAction.action.status === 'success') {
            serverActionStatus = serverAction.action.status
            setActionRunning(false)
            syncServers()
          }
        }
      }
    }
  }

  return (
    <Row>
      <Col sm={12} key={propsId} className="mb-3">
        <Card>
          {actionRunning ?
            <LoadingView progress={actionProgress} /> : ''
          }
          <CardBody className="d-none d-sm-block">
            <Row>
              <Col sm={6} md={4}>
                <small className="text-muted">
                  Όνομα
                </small>
                <div>
                  <Link to={"/servers/" + propsId}>
                    {name}
                  </Link>
                </div>
              </Col>
              <Col md={4} className="d-none d-md-block">
                <small className="text-muted">Ip address</small>
                <div>
                  {public_net.ipv4.ip}
                </div>
              </Col>
              <Col sm={6} md={4} className="d-flex align-items-center">
                {/* {<small className="text-muted">Κατάσταση</small>} */}
                <div>
                  <ServerStatus
                    handleStatusClick={invokeAction}
                    status={status}
                    serverId={propsId} />
                </div>
              </Col>
            </Row>
          </CardBody>
          <div className="d-block d-sm-none">
            <Col sm={12} className="text-center mb-3">
              <small className="text-muted">Όνομα</small>
              <div>
                {name}
              </div>
            </Col>
            <Col sm={12} className="text-center mb-3">
              <small className="text-muted">Ip address</small>
              <div>
                {public_net.ipv4.ip}
              </div>
            </Col>
            <Col sm={12} className="text-center">
              <small className="text-muted">Κατάσταση</small>
              <div>
                <ServerStatus
                  handleStatusClick={invokeAction}
                  status={status}
                  serverId={propsId} />
              </div>
            </Col>
          </div>
        </Card>
      </Col>
    </Row>
  )
}