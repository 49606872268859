import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-component'
import ContactsItem from './ContactsItem'
import ContactsFilters from './ContactsFilters'
import ContactsAdd from './ContactsAdd'
import { getContacts } from '../Api/Contacts'
import { MainLoader } from '../Components/Loader/Loader'
import auth0Client from '../Auth'
import Fuse from 'fuse.js'
import { Row, Col, Card, CardHeader, Button, FormGroup, Input, Label } from 'reactstrap'
import NoData from '../Components/NoData/NoData'

export default function Contacts() {
  const [contacts, setContacts] = useState(null)
  const [contactsError, setContactsError] = useState(false)
  const [newContactModal, setCNewContactModal] = useState(false)
  const [filterIsVendor, setFilterIsVendor] = useState(false)
  const [filterIsClient, setFilterIsClient] = useState(false)
  const [filterHasDebt, setFilterHasDebt] = useState(false)
  const [filterText, setFilterText] = useState('')

  const filterSetters = {
    setFilterIsVendor,
    setFilterIsClient,
    setFilterHasDebt,
    setFilterText
  }

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollPosition");
    }
  }

  const contactOnClick = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY)
  }

  const toggleNewContactModal = () => {
    setCNewContactModal(!newContactModal)
  }

  // Handle filters change
  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const functionName = 'set' + name.charAt(0).toUpperCase() + name.slice(1)

    filterSetters[functionName](value)
  }

  // Filter each data item - used in filterItems()
  const filterItem = (source, itemName) => {
    source = source.filter((item) => item[itemName])
    return source
  }

  const filterItemText = (data, text) => {
    var fuseOptions = {
      keys: ['name']
    }
    var fuse = new Fuse(data, fuseOptions)

    data = fuse.search(text)
    return data
  }

  // Apply filters on data
  const filterItems = (data) => {
    //Filter isVendor
    data = filterIsVendor ? filterItem(data, 'isVendor') : data

    //Filter isClient
    data = filterIsClient ? filterItem(data, 'isClient') : data

    //Filter hasDebt
    data = filterHasDebt ? filterItem(data, 'debt') : data

    // Filter Text
    data = filterText ? filterItemText(data, filterText) : data

    return data
  }

  const renderNoData = () => {
    if (contactsError) {
      return (
        <NoData getData={getContactsFromApi} />
      )
    } else {
      return (
        <MainLoader />
      )
    }
  }

  // Used filtered data to render items
  const renderItems = (data) => {
    var filteredData = filterItems(data)

    if (!filteredData[0]) {
      return (
        <div className="container">
          <div className="row">
            <h3 style={{ margin: "0 auto" }}>No data matched those filters</h3>
          </div>
        </div>
      )
    }

    return (
      filteredData.map(contact => (
        <ContactsItem key={contact.id} {...contact} contactOnClick={contactOnClick} />
      ))
    )
  }

  const getContactsFromApi = async (callback) => {
    const myIdToken = auth0Client.getIdToken()

    setContactsError(false)
    try {
      const contacts = await getContacts(myIdToken)
      setContacts(contacts)
      callback()
    } catch (error) {
      setContactsError(true)
    }
  }

  useEffect(() => {
    getContactsFromApi(handleScrollPosition)
  }, [])

  return (
    <>
      <ContactsAdd isOpen={newContactModal} handleClose={toggleNewContactModal} reloadContacts={getContactsFromApi} />

      <Row className="mb-5">
        <Col>
          <Card>

            <CardHeader className="clearfix">
              <h3 className="m-0 float-left">
                <i className="fas fa-user">&nbsp;</i>
                  Επαφές{' '}<small>{contacts && '(' + contacts.length + ')'}</small>
              </h3>
              <Button color="link" className="float-right" onClick={toggleNewContactModal}>
                <i className="fas fa-plus-circle">&nbsp;</i>
                Προσθήκη
              </Button>
            </CardHeader>

            <Col>
              <ContactsFilters
                filterIsVendor={filterIsVendor}
                filterIsClient={filterIsClient}
                filterHasDebt={filterHasDebt}
                onChange={handleInputChange} />
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="filterText">Αναζήτηση</Label>
                <Input id="filterText" name="filterText" value={filterText} onChange={handleInputChange} />
              </FormGroup>
            </Col>

          </Card>
        </Col>
      </Row>
      <Masonry className={'row'}>
        {contacts ? renderItems(contacts) : renderNoData()}
      </Masonry>
    </>
  )
}