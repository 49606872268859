import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import ServersItem from "./ServersItem"
import { MainLoader } from '../Components/Loader/Loader'
import { getServers } from "../Api/Servers"

import { Row, Col, Card, CardHeader, Button, } from 'reactstrap'


export default function Servers() {
  const [servers, setServers] = useState(null)

  const getServersFromApi = async () => {
    const data = await getServers()

    if (data.error) {
      alert('ERROR')
    } else {
      setServers(data.servers);
    }
  }

  const syncServers = () => {
    setServers(null)
    getServersFromApi()
  }

  useEffect(() => {
    getServersFromApi()
  }, [])
  

  return (
    <>
      <Row className="mb-5">
        <Col>
          <Card>
            <CardHeader className="clearfix">
              <h3 className="m-0 float-left">
                <i className="fas fa-server">&nbsp;</i>
                Servers
              </h3>
              <Link to='/servers/add'>
                <Button color="link" className="float-right">
                  <i className="fas fa-plus-circle"></i>
                  {' '}
                  Add new server
                </Button>
              </Link>
            </CardHeader>

            <Col>
              <div className="mb-2 mt-2">
                <Button color="link" onClick={syncServers}>
                  <i className="fas fa-sync-alt"></i>
                  {' '}Sync
                </Button>
              </div>
            </Col>
          </Card>
        </Col>
      </Row>

      {servers ?
        (
          servers.map(item => (
            <ServersItem key={item.id} {...item} syncServers={syncServers} />
          ))
        ) :
        <MainLoader />
      }
    </>
  )
}