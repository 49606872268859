import React from 'react'
import { CustomInput } from 'reactstrap';

const ContactsFilters = (props) => {
  return (
    <div className="form-group mb-3 mt-3 pl-3">

      <div className="form-check form-check-inline mr-4">
        <CustomInput
          type={'switch'}
          label={'Πελάτες'}
          name={'filterIsClient'}
          id={'filterIsClient'}
          checked={props.filterIsClient}
          onChange={props.onChange}
        />
      </div>

      <div className="form-check form-check-inline mr-4">
        <CustomInput
          type={'switch'}
          label={'Προμηθευτές'}
          name={'filterIsVendor'}
          id="filterIsVendor"
          checked={props.filterIsVendor}
          onChange={props.onChange}
        />
      </div>

      <div className="form-check form-check-inline mr-4">
        <CustomInput
          type={'switch'}
          label={'Με οφειλή'}
          name={'filterHasDebt'}
          id={'filterHasDebt'}
          checked={props.filterHasDebt}
          onChange={props.onChange}
        />
      </div>

    </div>
  )
}

export default ContactsFilters
