import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getServerTypes, getServerImages, postServers } from '../Api/Servers'
import Notify from '../Components/Notifications/Notification'

import {
  Row, Col, Card, CardHeader, CardBody, Button,
  FormGroup, Label, Input, CustomInput
} from 'reactstrap'

const formDefaults = {
  name: '',
  server_type: 'cx11',
  image: 'ubuntu-20.04',
}

function NewServer({ history }) {
  const [{ name, server_type, image }, setValues] = useState(formDefaults)
  const [server_types, setServer_types] = useState(null)
  const [images, setImages] = useState(null)
  const [submit, setSubmit] = useState(false)
  const [notifications, setNotifications] = useState([])

  const resetForm = () => {
    setValues({ ...formDefaults })
    setSubmit(false)
  }

  const handleChange = ({ target }) => {
    const { name, value } = target
    setValues(prevState => ({ ...prevState, [name]: value }))
  }

  const onSubmit = e => {
    e.preventDefault()
    setSubmit(true)

    return postServersToAPI({ name, image, server_type })
      .then(() => {
        setSubmit(false)
        resetForm()
        history.push('/servers')
      })
      .catch(err => {
        console.log('Error', err)
        setSubmit(false)
      })
  }

  const onCancelClick = () => {
    history.push('/servers')
  }

  const getServerImagesFromAPI = () => {
    getServerImages()
      .then(({ images }) => setImages(images))
  }

  const getServerTypesFromAPI = () => {
    getServerTypes()
      .then(({ server_types }) => setServer_types(server_types))
  }

  const postServersToAPI = (data) => {
    return postServers(data)
  }

  const throwNewNotification = (title, text, type) => {
    const newNotification = <Notify title={title} text={text} type={type} delay={5000} />
    setNotifications(prevState => ({ ...prevState, newNotification }))
  }

  useEffect(() => {
    getServerImagesFromAPI()
    getServerTypesFromAPI()
  }, [])

  return (
    <>

      {notifications.length ?
        notifications.map(item => (
          item
        )) : ''
      }

      <Row className="mb-5">
        <Col>
          <Card>
            <CardHeader className="clearfix">
              <h3 className="m-0 float-left">
                <i className="fas fa-server">&nbsp;</i>
                Add new Server
              </h3>
              <Button color="link" className="float-right" onClick={onCancelClick}>
                <i className="fas fa-times"></i>
                {' '}
                Cancel
              </Button>
            </CardHeader>

            <CardBody>
              <form autoComplete="off">
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input type="text" name="name" id="name" value={name} required
                    onChange={handleChange} autoComplete="off" />
                </FormGroup>

                <FormGroup>
                  <Label for="server_type">Server type</Label>
                  <CustomInput type="select" id="server_type" name="server_type" required
                    value={server_type} onChange={handleChange}>
                    <option value="">{''}</option>
                    {server_types &&
                      server_types.map(item => (
                        <option key={item.name} value={item.name}>{item.description}</option>
                      ))
                    }
                  </CustomInput>
                </FormGroup>

                <FormGroup>
                  <Label for="image">Image</Label>
                  <CustomInput type="select" id="image" name="image" required
                    value={image} onChange={handleChange}>
                    <option value="">{''}</option>
                    {images &&
                      images.map(item => (
                        <option key={item.name} value={item.name}>{item.description}</option>
                      ))
                    }
                  </CustomInput>
                </FormGroup>

                <FormGroup>
                  <Button disabled={!name || !server_type || !image} onClick={onSubmit} >
                    <i className="fas fa-plus-circle"></i>
                    {' '}Add Server{' '}
                    {submit &&
                      <i className='fas fa-circle-notch fa-spin '> </i>
                    }
                  </Button>
                </FormGroup>

              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Button
        onClick={() => throwNewNotification('The title...', 'Some awesome Text', 'success')}>
        Notify!
      </Button>

    </>
  )
}

export default withRouter(NewServer)