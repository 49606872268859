import React, { Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NavBar from './Components/NavBar/NavBar'
import Contact from './Contacts/Contact'
import Contacts from './Contacts/Contacts'
import Lead from './Leads/Lead'
import Leads from './Leads/Leads'
import Server from './Servers/Server'
import Servers from './Servers/Servers'
import AddServer from './Servers/NewServer'

import { Container } from 'reactstrap'

export default function Template() {
  return (
    <Fragment>
      <NavBar/>
      <Container>
        <Switch>
          <Route exact path='/' render={() => <Redirect to='/contacts' />} />
          
          <Route exact path='/contacts' component={Contacts} />
          <Route exact path='/contacts/:contactId' component={Contact} />

          <Route exact path='/leads' component={Leads} />
          <Route exact path='/leads/:leadId' component={Lead} />

          <Route exact path='/servers/' component={Servers} />
          <Route exact path='/servers/add' component={AddServer} />
          <Route exact path='/servers/:serverId' component={Server} />
        </Switch>
      </Container>
    </Fragment>
  );
}

