import React from 'react'
import {Link} from 'react-router-dom'

const ContactsItem = (props) => {

  return (
    <div key={props.id} className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
      <div className="card mb-3 app-card app-card-anim">
        <div className="card-body">
          <Link className="card-link" to={`/contacts/${props.id}`} onClick={props.contactOnClick}>
            <h5 className="m-0">{props.name}</h5>
          </Link>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            { props.isClient ? <span className="badge badge-pill badge-success mr-1">Πελάτης</span> : '' }
            { props.isVendor ? <span className="badge badge-pill badge-info">Προμηθευτής</span> : '' }
          </li>
          {props.debt &&
            <li className="list-group-item bg-warning">
            Εκκρεμεί οφειλή <strong>€{props.debt}</strong>
            </li>
          }
          { props.email &&
            <li className="list-group-item">
              <small className="text-muted">Email</small>
              <div><a href={'mailto:'+props.email}>{props.email}</a></div>
            </li>
          }
          { props.phone &&
            <li className="list-group-item">
              <small className="text-muted">Τηλέφωνο</small>
              <div><a href={'tel:'+props.phone}>{props.phone}</a></div>
            </li>
          }
        </ul>
      </div>
    </div>
  )
}

export default ContactsItem
