import React from 'react'
import CurrencyFormat from 'react-currency-format'

const Currency = (props) => {

    const {number} = props

    return (
        <CurrencyFormat 
            value={number} 
            displayType={'text'} 
            thousandSeparator={'.'} 
            decimalSeparator={','}
            prefix={'€'} 
        />
    )
}

export default Currency;