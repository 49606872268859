import React from 'react'
import { Notification } from 'react-pnotify'


const Notify = props => {

    const { title, text, type } = props

    const defaults = {
        delay: 2000,
        shadow: true,
        hide: true,
    }

    return (
        <Notification
            type={type}
            title={title}
            text={text}
            delay={props.delay || defaults.delay}
            shadow={props.shadow || defaults.shadow}
            hide={ props.hide || defaults.hide}
            nonblock={true}
            desktop={true}
        />
    )
}
 
export default Notify