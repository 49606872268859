import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col } from 'reactstrap'


export default function NoData(props) {
  const { getData } = props
  return (
    <Col style={{ textAlign: 'center' }}>
      <div>There was an error while trying to get data</div>
      <props.as color="link" onClick={getData}>
        <i className="fas fa-redo">&nbsp;</i>Try again
      </props.as>
    </Col>
  )
}

NoData.defaultProps = {
  as: Button
}

NoData.propTypes = {
  as: PropTypes.node,
  getData: PropTypes.func.isRequired,
}
