import { myAxios } from '../Api/Axios'

// GET requests
export const getContacts = (token) => myAxios.get('contacts', {
  headers: { 'Authorization': `Bearer ${token}` } })
  .then(({ data }) => data)

export const getContactById = (id, token) => myAxios.get(`contacts/${id}`, {
  headers: { 'Authorization': `Bearer ${token}` } })
  .then(({ data }) => data)

export const getContactLeads = (id, token) => myAxios.get(`contacts/${id}/leads`, {
  headers: { 'Authorization': `Bearer ${token}` } })
  .then(({ data }) => data)

// POST requests
export const postContact = (data, token) => myAxios.post( `contacts`, {
  data
}, {
    headers: { 'Authorization': `Bearer ${token}` }
  }).then( (response) => { return response })

export const postContactPhone = (data, token) => myAxios.post(`contacts/phone`, {
  data
}, {
    headers: { 'Authorization': `Bearer ${token}` }
  }).then((response) => { return response })

  // PUT requests
export const putContact = (id, data, token) => myAxios.put(`contacts/${id}`, {
  data
}, {
    headers: { 'Authorization': `Bearer ${token}` }
  }).then((response) => { return response })