import * as rax from 'retry-axios'
import axios from 'axios'


const protocol = "https"
// const hostname = "w3c-admin-backend.herokuapp.com"
// const hostname = "127.0.0.1"
const hostname = "europe-west1-w3c-admin.cloudfunctions.net"
const path = "api"
const port = ""

const useLocalhostBackend = false

const baseURL = window.location.hostname === 'localhost' && useLocalhostBackend ? 'http://127.0.0.1:3001/w3c-admin/europe-west1/api' : `${protocol}://${hostname}${port ? ':' + port : ''}/${path && path}`


export const myAxios = axios.create()

myAxios.defaults.baseURL = baseURL
myAxios.defaults.timeout = 3000
myAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

myAxios.defaults = {
  raxConfig: {
    instance: myAxios
  }
}

rax.attach(myAxios)
