import axios from 'axios'
import * as rax from 'retry-axios'

const hetznerApiKey = 'eG8qy10o0WvGGo8QQQnIj0WueA03C8bttOEAOKb2YMOcaem79wxw0FJPy6rCOKZK'

const headers = { 'Authorization': `Bearer ${hetznerApiKey}` }

const hetznerAxios = axios.create()

hetznerAxios.defaults.baseURL = 'https://api.hetzner.cloud/v1/'
hetznerAxios.defaults.timeout = 3000
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + hetznerApiKey
hetznerAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

hetznerAxios.defaults = {
    raxConfig: {
        instance: hetznerAxios
    }
}

rax.attach(hetznerAxios)

export const getServers = () => hetznerAxios.get('servers', {
    headers
})
.then(({ data }) => data)

export const postServers = (data) => hetznerAxios.post('servers', { ...data }, {
    headers
})
    .then(({ data }) => data)
    .catch(({ response }) => response)

export const getServer = (serverid) => hetznerAxios.get(`servers/${serverid}`, {
    headers
}).then(({ data }) => data)

export const getServerAction = (serverid, actionId) => hetznerAxios.get(`servers/${serverid}/actions/${actionId}`, {
    headers
}).then(({ data }) => data)
    
export const invokeServerAction = (id, action) => hetznerAxios.post(`servers/${id}/actions/${action}`, {}, {
    headers
}).then(({ data }) => data)

export const getLocations = () => hetznerAxios.get('locations', {
    headers
}).then(({ data }) => data)

export const getServerTypes = () => hetznerAxios.get('server_types', {
    headers
}).then(({ data }) => data)

export const getServerImages = () => hetznerAxios.get('images', {
    headers,
    params: { 'type': 'system' }
}).then(({ data }) => data)