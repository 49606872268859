import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Moment from 'react-moment'
import 'moment/locale/el'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Css/bootstrap-theme.css'
import './Css/index.css'

// Set the locale for every react-moment instance to Greek.
Moment.globalLocale = 'el'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register()
