import React from 'react'

export default function ClientPortal() {
  return (
    <div>
      <p>Client Portal page</p>
      <p>
        TODO: Page template design
      </p>
    </div>
  )
}
