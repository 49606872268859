import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-component'
import { getLeads } from '../Api/Leads'
import LeadsCard from '../Components/Cards/LeadsCard'
import { MainLoader } from '../Components/Loader/Loader'
import auth0Client from '../Auth'
// import urlParse from 'url-parse'

import { Row, Col, Card, CardHeader, Button } from 'reactstrap'
import NoData from '../Components/NoData/NoData'


export default function Leads(props) {

  const [leads, setLeads] = useState(null)
  const [leadsError, setLeadsError] = useState(false)

  // Use filtered data to render items
  const renderItems = (data) => {
    return (
      data.map(lead => (
        <LeadsCard key={lead.id} {...lead} />
      ))
    )
  }

  const getLeadsFromApi = () => {
    const myIdToken = auth0Client.getIdToken()
    const params = {
      perPage: 500,
      page: 1
    }
    setLeadsError(false)

    getLeads(myIdToken, params)
      .then(leads => setLeads(leads))
      .catch(error => setLeadsError(true))
  }

  useEffect(() => {
    getLeadsFromApi()
  }, [])

  return (
    <>
      <Row className="mb-5">
        <Col>
          <Card>
            <CardHeader className="clearfix">
              <h3 className="m-0 float-left">
                <i className="fas fa-shopping-cart mr-2"></i>
                Έργα
              </h3>
              <Button color="link" className="float-right" disabled>
                <i className="fas fa-plus-circle"></i>
                Add new lead
              </Button>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Masonry className={'row'}>
        {leads ? renderItems(leads) :
          leadsError ? <NoData getData={getLeadsFromApi} /> : <MainLoader />
        }
      </Masonry>
    </>
  )
}
