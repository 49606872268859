import React, { Component } from 'react'
import { postContact, postContactPhone } from '../Api/Contacts'
import auth0Client from '../Auth'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap'

class ContactsAdd extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      backdrop: true,
      unmountOnClose: true,

      name: '',
      email: '',
      phone: '',
      isClient: false,
      isVendor: false,
      submit: false,
    }
  }

  resetForm = () => {
    this.setState({
      name: '',
      email: '',
      phone: '',
      isClient: false,
      isVendor: false,
      submit: false,
    })
  }

  handleNameChange = (e)  => {
    this.setState({  name: e.target.value })
  }

  handleEmailChange = (e) => {
    this.setState({  email: e.target.value })
  }

  handlePhoneChange = (e) => {
    this.setState({ phone: e.target.value })
  }

  handleClientClick = (e) => {
    this.setState({ isClient: !this.state.isClient })
  }

  handleVendorClick = (e) => {
    this.setState({ isVendor: !this.state.isVendor })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (event.target.checkValidity()) 
      this.setState({ submit: true })
  }

  insertContact = async () => {
    const { name, email, isClient, isVendor } = this.state
    const myIdToken = auth0Client.getIdToken()

    const response = await postContact({ name, email, isClient, isVendor }, myIdToken)

    return response
  }

  insertContactPhone = async (contacts_id) => {
    const number = this.state.phone
    const myIdToken = auth0Client.getIdToken()

    const response = await postContactPhone({ number, contacts_id }, myIdToken)

    return response
  }

  async componentDidUpdate(prevProps, prevState) {
    
    if (this.state.submit)  {
      const response = await this.insertContact()
      await this.insertContactPhone(response.data.insertId)
      
      this.setState({ submit: false })

      if (response.status === 200) {
        this.props.reloadContacts()
        this.props.handleClose()
        this.resetForm()
      }
    }
  }

  render() {
    
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.handleClose} centered={true}>
        <form onSubmit={this.handleSubmit} noValidate>

          <ModalHeader toggle={this.props.handleClose}>
            Προσθήκη επαφής
          </ModalHeader>

          <ModalBody>

            <div className="form-group">
              <label htmlFor="exampleIcontacts-add-namenputEmail1">Όνομα</label>
              <input type="text" className="form-control" id="contacts-add-name" name="name" aria-describedby="nameHelp" required
                value={this.state.name} onChange={this.handleNameChange} />
              <small id="nameHelp" className="form-text text-muted">Το πλήρες όνομα της επαφής</small>
            </div>

            <div className="form-group">
              <label htmlFor="contacts-add-email">Email</label>
              <input type="email" className="form-control" id="contacts-add-email" name="email" aria-describedby="emailHelp"
                value={this.state.email} onChange={this.handleEmailChange} />
              <small id="emailHelp" className="form-text text-muted">Το χρειάζεσαι για να στέλνεις σημαντικές ειδοποιήσεις</small>
            </div>

            <div className="form-group">
              <label htmlFor="contacts-add-phone">Τηλέφωνο</label>
              <input type="text" className="form-control" id="contacts-add-phone" name="phone" aria-describedby="phoneHelp"
                value={this.state.phone} onChange={this.handlePhoneChange} />
              <small id="phoneHelp" className="form-text text-muted">Τηλέφωνο επικοινωνίας</small>
            </div>

            <div className="custom-control custom-checkbox inline">
              <input type="checkbox" className="custom-control-input" id="contacts-add-isClient" name="isClient" 
                value="1" checked={this.state.isClient} onChange={this.handleClientClick} />
              <label className="custom-control-label" htmlFor="contacts-add-isClient">Πελάτης</label>
            </div>

            <div className="custom-control custom-checkbox inline">
              <input type="checkbox" className="custom-control-input" id="contacts-add-isVendor" name="isVendor"
                value="1" checked={this.state.isVendor} onChange={this.handleVendorClick} />
              <label className="custom-control-label" htmlFor="contacts-add-isVendor">Προμηθευτής</label>
            </div>

            <small id="emailHelp" className="form-text text-muted">Η σχέση σου με αυτή την επαφή</small>

          </ModalBody>

          <ModalFooter>
            <Button color="link" onClick={this.resetForm}>Reset</Button>
            {' '}
            <Button disabled={this.state.submit} color="info">
              Insert {' '}
              {
                this.state.submit 
                ? <i className='fas fa-circle-notch fa-spin '></i>
                : ''
              }
            </Button>
          </ModalFooter>
        
        </form>
      </Modal>
    )
  }
}
 
export default ContactsAdd;