import React from 'react'
import { BarLoader, BounceLoader, BeatLoader } from 'react-spinners'
import { css } from '@emotion/core'
import './LoadingView.css'

export const MainLoader = () => {
  return (
    <div className="container">
      <div className="row">
        <div style={{ margin: '0 auto' }}>
          <BarLoader
            width={600}
            height={2}
            color={'#909090'}
          />
        </div>
      </div>
    </div>
  )
}

export const UserLoader = () => {
  const override = css`
      margin: 0 auto 10px;
    `

  return (
    <BounceLoader
      css={override}
      size={50}
      color={'#fff'}
    />
  )
}

export const LoadingView = (props) => {

  return (
    <div className="loading-view-container">
      {/* {<div className="loading-view" style={{right: props.progress + '%'}}></div>} */}
      <BeatLoader
        css={css`
          margin: 0 auto;
        `}
        size={15}
        color={'#888'}
      />
    </div>
  );
}
